import type { SbOverlay } from '~/storyblok/types/storyblok'

export enum SubscriptionOverlays {
  INFO = 'subscription/subscription-info',
}

export enum RxOverlays {
  LENS_TYPES = 'rx/rx_lens_types',
  LENS_QUALITY = 'rx/rx_lens_quality_index',
  TINTING_TYPES = 'rx/rx_tinting_types',
  PRESCRIPTION_VALUES_FIND = 'rx/rx_prescription_find',
}

export enum RxPrescriptionOverlays {
  SPHERE = 'rx/rx_prescription_sphere',
  CYLINDER = 'rx/rx_prescription_cylinder',
  AXIS = 'rx/rx_prescription_axis',
  PD = 'rx/rx_prescription_pd',
}

export type Overlays = RxOverlays | RxPrescriptionOverlays

export const useOverlay = (
  slug: Overlays | string,
  options: Parameters<typeof useRpc<'getCmsStory'>>[3] = {},
) => {
  const open = ref<boolean>(false)

  const useCmsPromise = useCmsStory<SbOverlay>({
    params: { baseFolder: CmsBaseFolder.Overlays, slug },
    options,
    key: `useOverlay-${slug}`,
  })

  const { data, error, status, execute: fetch } = useCmsPromise

  const toggle = (state?: boolean) => {
    open.value = state ?? !open.value
  }

  const available = computed(
    () => status.value === 'success' && Boolean(data.value),
  )
  const fetching = computed(() => !['success', 'error'].includes(status.value))
  const overlayTitle = computed(() => data.value?.content?.title)
  const linkText = computed(
    () => data.value?.content?.link_text || overlayTitle?.value || '',
  )
  const overlayContent = computed(() => data.value?.content?.content)

  return extendPromise(
    useCmsPromise.then(() => ({})),
    {
      fetch,
      available,
      fetching,
      overlayTitle,
      overlayContent,
      linkText,
      open,
      error,
      status,
      show: () => toggle(true),
      close: () => toggle(false),
      toggle,
    },
  )
}
